export function getFileExtension(input) {
    let fileExtension = '';

    if (typeof input === 'string') {
        // fileExtension = input.split('.').pop().split(/\#|\?/)[0];
        fileExtension = input.split('.').pop().split(/#|\?/)[0];
    } else if (input instanceof File) {
        fileExtension = input.name.split('.').pop();
    }

    return fileExtension;
}