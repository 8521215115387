import BlogsIcon from "../../assets/icons/grayIcon/black-blog.svg";
import BriefsIcon from "../../assets/icons/grayIcon/black-brief.svg";
import CopyLinkIcon from "../../assets/icons/postActionIcons/CopyLinkIcon.svg";
import EditIcon from "../../assets/icons/postActionIcons/EditIcon.svg";
import DeleteIcon from "../../assets/icons/postActionIcons/DeleteIcon.svg";
import ReportIcon from "../../assets/icons/postActionIcons/ReportIcon.svg";
import SaveIcon from "../../assets/icons/postActionIcons/SaveIcon.svg";
import user from "../../assets/icons/user.png";
import TestPost from "../../assets/icons/gallery/gallery5.jpeg";


export const hashTagData = [
    "#foryou",
    "#food",
    "#bbq",
    "#chicken",
    "#popular",
    "#dinner",
]

export const categoryData = ["Vegetarian", "Wheat", "Eggs"]

export const AddMenu = [
    { name: "Add New Blog", value: "Add New Blog", icon: BlogsIcon },
    { name: "Add New Briefs", value: "Add New Briefs", icon: BriefsIcon },
];

export const blogActionMenu = [
    { name: "Copy Link", value: "Copy Link", icon: CopyLinkIcon },
    { name: "Save Blog", value: "Save Blog", icon: SaveIcon },
    { name: "Report Blog", value: "Report Blog", icon: ReportIcon },
]

export const briefActionMenu = [
    { name: "Copy Link", value: "Copy Link", icon: CopyLinkIcon },
    { name: "Save Brief", value: "Save Brief", icon: SaveIcon },
    { name: "Report Brief", value: "Report Brief", icon: ReportIcon },
]

export const userActionMenu = [
    { name: "Copy Link", value: "Copy Link", icon: CopyLinkIcon },
    { name: "Edit", value: "Edit", icon: EditIcon },
    { name: "Delete", value: "Delete", icon: DeleteIcon },
]

export const notificationData = [
    { userImage: user, actionImage: TestPost, userName: "Eleanor Pena", description: "is added like on your brief. 23m" },
    { userImage: user, actionImage: TestPost, userName: "Jerome Bell", description: "is added like on your blog. 23m" },
    { userImage: user, actionImage: TestPost, userName: "Eleanor Pena", description: "is added like on your brief. 23m" },
]

export const postcategory = {
    "vegetarian": "Vegetarian",
    "vegan": "Vegan",
    "treeNuts": "Tree Nuts",
    "wheat": "Wheat",
    "soy": "Soy",
    "dairy": "Dairy",
    "eggs": "Eggs",
    "shellfish": "Shellfish",
    "fish": "Fish",
    "halal": "Halal",
    "gluten": "Gluten",
    "glutenFree": "Gluten Free",
    "peanut": "Peanut",
    "desserts": "Desserts",
}