import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, url, image, keywords, script }) => {
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={url} />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* allow robots */}
      <meta name="robots" content="index, follow" />


      {/* script */}
      <script type="application/ld+json">
        {script}
      </script>


    </Helmet>
  );
};

export default SEO;
