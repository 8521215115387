import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { GetCallApi } from "../../../Action/Action";
import { getTimeAgoShort } from "../../../Utils/common/getTimeAgoShort";
import { getFileExtension } from "../../../Utils/common/getFileExtension";
import "./index.scss";
import ScrollLoader from "../Reusable/ScrollLoader";

const Notification = () => {
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const token = useSelector((state) => state.login.LoginDetails.data.token);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const getNotificationData = async () => {
    setData([]);
    let seData = {
      url: `/v1/notification/1`,
      // body: sendData,
      headers: headers,
    };

    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      const getData = res.data.data?.map((p) => {
        return {
          ...p,
        };
      });
      setData(getData?.length > 0 ? getData : []);
    } else {
      setData([]);
    }
  };

  const fetchMoreData = async () => {
    try {
      let seData = {
        url: `/v1/notification/${page}`,
        // body: sendData,
        headers: headers,
      };

      let { data } = await GetCallApi(seData);
      if (data.data.length === 0) {
        setHasMore(false);
        return;
      }

      setData((prevData) => [...prevData, ...data.data]);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Error fetching more data:", error);
    }
  };

  useEffect(() => {
    getNotificationData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box>
        <InfiniteScroll
          dataLength={data}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<ScrollLoader />}
          style={{
            overflow: "hidden",
          }}
          scrollableTarget="component_wrapper"
        >
          {data?.map((item, index) => (
            <Box className="content_container" key={index}>
              <div className="content_wraper">
                <img
                  className="image"
                  src={
                    item?.sender?.profilePic
                      ? item?.sender?.profilePic
                      : item?.sender?.profilePic
                  }
                  alt=""
                />
                <div>
                  <div className="username">
                    {item?.sender?.firstName + " " + item?.sender?.lastName}
                  </div>
                  <div className="description">
                    {item?.message &&
                      `${item.message}.${" "}${getTimeAgoShort(
                        item.createdAt
                      )}`}
                  </div>
                </div>
              </div>
              <Box>
                {item?.notificationType === "follow" ? (
                  <Button
                    sx={{
                      "&.MuiButtonBase-root": {
                        "&:hover": {
                          color: "#E84127",
                          border: "1px solid #E84127",
                        },
                      },
                    }}
                    className="followbtn"
                    //   onClick={() => onFollowClick(post)}
                    variant="outlined"
                  >
                    {/* {post.followStatus ? t("Unfollow") : t("Follow")} */}
                    Follow
                  </Button>
                ) : item?.contentType === "brief" ? (
                  item?.briefID?.mediaURL.length &&
                  getFileExtension(item?.briefID?.mediaURL[0]) !== "mp4" && (
                    <img
                      className="actionImage"
                      src={item?.briefID?.mediaURL[0]}
                      alt=""
                    />
                  )
                ) : (
                  item?.blogID?.mediaURL.length && (
                    <img
                      className="actionImage"
                      src={item?.blogID?.mediaURL[0]}
                      alt=""
                    />
                  )
                )}
              </Box>
            </Box>
          ))}
        </InfiniteScroll>
      </Box>
    </>
  );
};

export default Notification;
