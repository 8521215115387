import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Button,
  Menu,
  Switch,
  CircularProgress,
  Backdrop,
  Modal,
} from "@mui/material";
import { Close, Menu as MenuIcon } from "@mui/icons-material";

import logo from "../../assets/media/mainLogo.svg";
import profile_drop_icon from "../../assets/media/profile_drop_icon.svg";
import forwordArrow from "../../assets/icons/whiteIcon/forwordarrow.svg";
import whiteIcon from "../../assets/icons/whiteIcon/white-icon.svg";
import BlackArrowIcon from "../../assets/icons/whiteIcon/black-arrow.svg";
import defaultUser from "../../assets/icons/org_user.png";

import Menus from "../../Modules/Components/Reusable/Menus";
import { AddMenu } from "../../Utils/static/staticData";
import { useDispatch, useSelector } from "react-redux";
import { setOpen as setOpenDialog } from "../../reducers/globalpopup";
import CustomDrawer from "../../Modules/Components/Reusable/CustomDrawer";
import Notification from "../../Modules/Components/Notification";
import { useMediaQuery } from "react-responsive";
import { Iconify } from "../../Modules/Components/Iconify";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { resetLogin } from "../../reducers/login";
import { resetMenu, setDarkMode } from "../../reducers/menu";
import { DeleteCallApi } from "../../Action/Action";
import { toast } from "react-toastify";
import WhiteIcon from "../../assets/icons/whiteIcon/search_white.svg";
import WhiteBrief from "../../assets/icons/whiteIcon/white-brief.svg";

import GrayHome from "../../assets/icons/grayIcon/gray-home.svg";
import GraySearch from "../../assets/icons/grayIcon/gray-search.svg";
import GrayBlogs from "../../assets/icons/grayIcon/gray-blogs.svg";
import GrayArticle from "../../assets/icons/grayIcon/gray-article.svg";
import GrayBriefs from "../../assets/icons/grayIcon/gray-briefs.svg";
import GrayChat from "../../assets/icons/grayIcon/gray-chat.svg";
import GrayProfile from "../../assets/icons/grayIcon/gray-profile.svg";
import GraySettings from "../../assets/icons/grayIcon/gray-settings.svg";
import GrayLogout from "../../assets/icons/grayIcon/gray-logout.svg";
// import GrayContact from "../../assets/icons/grayIcon/gray-contact.svg";
// Active Icons
import HomeActive from "../../assets/icons/MenuActiveIcon/home.svg";
import SearchActive from "../../assets/icons/MenuActiveIcon/search.svg";
import BlogsActive from "../../assets/icons/MenuActiveIcon/blogs.svg";
import ArticleActive from "../../assets/icons/MenuActiveIcon/article.svg";
import BriefsActive from "../../assets/icons/MenuActiveIcon/briefs.svg";
import ChatActive from "../../assets/icons/MenuActiveIcon/chat.svg";
import ProfileActive from "../../assets/icons/MenuActiveIcon/profile.svg";
// import ContactActive from "../../assets/icons/MenuActiveIcon/contactUs.svg";
import SettingsActive from "../../assets/icons/MenuActiveIcon/settings.svg";
import LogoutActive from "../../assets/icons/MenuActiveIcon/logout.svg";
import { useMediaQuery as muiMediaQuery } from "@mui/material";
import "./index.scss";

const App = () => {
  const drawerWidth = 300;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [deleteAccountModal, setDeleteAccModal] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [username, setUserName] = useState("");
  const [loader, setLoader] = useState(false);
  const [checked, setChecked] = useState();
  const [appBarRef, setAppBarRef] = useState(null);
  const accountInformation = useSelector((state) => state.login.LoginDetails);

  const isMobile = useMediaQuery({
    query: "(max-width: 769px) and (min-width: 320px)",
  });

  const drawerMobile = muiMediaQuery("(max-width: 768px)");
  console.log("🚀 ~ file: index.js:96 ~ App ~ drawerMobile:", drawerMobile);

  const darkMode = useSelector((state) => state.headerMenu.darkMode);
  const dispatch = useDispatch();
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const openUMenu = Boolean(anchorEl2);
  const { t } = useTranslation();

  const pathname = useLocation().pathname;

  const handleCloseUMenu = () => {
    setAnchorEl2(null);
  };

  const handleClickUMenu = (event) => {
    setAnchorEl2(event?.currentTarget);
  };
  let nav = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const onProfileClick = () => {
    setAnchorEl2(null);
    nav("/profile");
  };
  const onSaveItem = () => {
    setAnchorEl2(null);
    nav("/saveItem");
  };
  const onPasswordChange = () => {
    setAnchorEl2(null);
    nav("/changePassword");
  };
  const onAccountBlock = () => {
    setAnchorEl2(null);
    nav("/blockedaccounts");
  };
  const onTermasandCondition = () => {
    setAnchorEl2(null);
    nav("/terms-condition");
  };
  const onPrivacyPolicy = () => {
    setAnchorEl2(null);
    nav("/privacy-policy");
  };
  // const onContactUs = () => {
  //   setAnchorEl2(null);
  //   nav("/contact-us");
  // };
  const onHelp = () => {
    setAnchorEl2(null);
    nav("/helpcenter");
  };

  const handleLogOut = () => {
    localStorage.clear("expireDate");
    dispatch(resetLogin());
    dispatch(resetMenu());
    handleCloseUMenu();
    nav("/", { replace: true });
  };

  const onDeleteAccount = () => {
    setAnchorEl2(null);
    setDeleteAccModal(true);
  };

  const handleDeleteClose = () => {
    setDeleteAccModal(false);
  };

  const onDeleteAccountFinal = async () => {
    if (!username) {
      setUsernameError("Please enter username first.");
      return;
    }

    setLoader(true);
    const headers = {
      Authorization: `Bearer ${accountInformation.data.token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    };
    let seData = {
      url: `/v1/delete-user-account/${username}`,
      headers: headers,
    };
    let res = await DeleteCallApi(seData);
    if (res?.status === 200) {
      setDeleteAccModal(false);
      setUserName("");
      handleLogOut();
      setTimeout(() => {
        toast.success("Delete account successfully", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }, 100);
    } else {
      setUsernameError("Username is not valid.");
    }
    setLoader(false);
  };

  const menu = [
    {
      icon: GrayHome,
      icon1: HomeActive,
      menu: t("Home"),
      link: "/",
      isVisible: true,
    },
    {
      icon: GrayArticle,
      icon1: ArticleActive,
      menu: t("Articles"),
      link: "/article",
      isVisible: true,
    },
    {
      icon: darkMode ? WhiteIcon : GraySearch,
      icon1: SearchActive,
      menu: t("search"),
      link: "/search",
      isVisible: true,
      // btn: true,
    },
    {
      icon: GrayBlogs,
      icon1: BlogsActive,
      menu: t("Posts"),
      link: "/posts",
      isVisible: true,
    },
    {
      icon: darkMode ? WhiteBrief : GrayBriefs,
      icon1: BriefsActive,
      menu: t("Briefs"),
      link: "/brief",
      isVisible: true,
    },
    // {
    //   icon: GrayChat,
    //   icon1: ChatActive,
    //   menu: t("Chats"),
    //   link: "/chats",
    //   isVisible: true,
    // },
    // {
    //   icon: GrayProfile,
    //   icon1: ProfileActive,
    //   menu: t("Profile"),
    //   link: "/studio",
    //   isVisible: true,
    // },
    // {
    //   icon: GrayContact,
    //   icon1: ContactActive,
    //   menu: t("Contact Us"),
    //   link: "/contact-us",
    // },
    // {
    //   icon: GraySettings,
    //   icon1: SettingsActive,
    //   menu: t("Settings"),
    //   link: "/settings",
    //   isVisible: true,
    // },
    {
      icon: GrayLogout,
      icon1: LogoutActive,
      menu: t("Logout"),
      link: "",
      btn: true,
      onClick: handleLogOut,
      isVisible: accountInformation?.authenticate,
    },
  ];

  const drawerContent = (
    <List
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <div style={{ overflow: "auto" }}>
        {menu.map(
          (item, index) =>
            item.isVisible && (
              <Link to={item.link} key={index} onClick={item?.onClick}>
                <ListItem
                  button
                  key={index}
                  sx={{
                    p: "16px",
                    borderRadius: "12px",
                    backgroundColor: item.link === pathname && "#E940251A",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "24px", mr: "16px" }}>
                    <img
                      src={item.link === pathname ? item.icon1 : item.icon}
                      alt="menu"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.menu}
                    sx={{
                      span: {
                        fontFamily: "Inter",
                        fontWeight: item.link === pathname ? 600 : 500,
                        fontSize: "18px",

                        color: item.link === pathname ? "#E84127" : "#777777",
                      },
                    }}
                  />
                </ListItem>
              </Link>
            )
        )}
      </div>
      <div>
        <>
          <Box
            sx={{
              color: "#666",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "120%",
              textAlign: "center",
            }}
          >
            Maindiish © 2024. All rights reserved.
            <br />
            <Link style={{ fontSize: "12px" }} to="/privacy-policy">
              Privacy Policy
            </Link>{" "}
            &nbsp; | &nbsp;
            <Link style={{ fontSize: "12px" }} to="/terms-condition">
              Terms & Condition
            </Link>{" "}
            &nbsp; | &nbsp;
            <Link style={{ fontSize: "12px" }} to="/about-us">
              About Us
            </Link>
            &nbsp; | &nbsp;
            <Link style={{ fontSize: "12px" }} to="/contact-us">
              Contact Us
            </Link>
          </Box>
        </>
      </div>
    </List>
  );
  console.log("appbarRef", appBarRef);

  const onDarkModeChange = (checked) => {
    setChecked(checked);
    dispatch(setDarkMode(checked));
  };

  const onUserNameChange = (event) => {
    setUserName(event.target.value);
    setUsernameError("");
  };

  return [
    "/profile",
    "/changePassword",
    "/blockedaccounts",
    "/termsandConditions",
    "/helpcenter",
    "/saveItem",
  ].includes(pathname) ? (
    <Outlet />
  ) : (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* App Bar */}
      <AppBar
        position="fixed"
        ref={(ref) => setAppBarRef(ref)}
        sx={{
          zIndex: 100,
          backgroundColor: "white",
          boxShadow: "none",
          borderBottom: !isMobile && "1px solid #ddd",
        }}
      >
        <Toolbar sx={{ p: "14px 24px" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: !isMobile && "none", color: "primary.main" }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
          >
            <img src={logo} alt="" />
          </Typography>
          <Box sx={{ display: "flex", gap: !isMobile ? 2 : 1 }}>
            {!isMobile && (
              <IconButton
                sx={{
                  p: !isMobile && "13px",
                  border: "1px solid #CCCCCC",
                  borderRadius: "6px",
                }}
              >
                <Iconify
                  icon="iconamoon:search-thin"
                  sx={{
                    color: "#222222",
                    width: "24px",
                    height: "24px",
                    strokeWidth: 1.5,
                  }}
                />
              </IconButton>
            )}
            <Menus menu={AddMenu} handleChangeValue={(selectedMenu) => {}}>
              {({ handleClick }) => (
                <IconButton
                  sx={{
                    p: !isMobile && "13px",
                    border: !isMobile && "1px solid #CCCCCC",
                    borderRadius: "6px",
                  }}
                  onClick={(e) => {
                    if (accountInformation?.authenticate) {
                      handleClick(e);
                    } else {
                      dispatch(setOpenDialog(true));
                    }
                  }}
                  tabIndex={0}
                >
                  <Iconify
                    icon="formkit:add"
                    sx={{
                      color: "#222222",
                      width: "24px",
                      height: "24px",
                      strokeWidth: 1.5,
                    }}
                  />
                </IconButton>
              )}
            </Menus>
            <CustomDrawer title="Notification" comp={<Notification />}>
              {({ setOpen }) => (
                <IconButton
                  sx={{
                    p: !isMobile && "13px",
                    border: !isMobile && "1px solid #CCCCCC",
                    borderRadius: "6px",
                  }}
                  onClick={() => {
                    if (accountInformation?.authenticate) {
                      setOpen(true);
                    } else {
                      dispatch(setOpenDialog(true));
                    }
                  }}
                >
                  <Iconify
                    icon="mi:notification"
                    sx={{
                      color: "#222222",
                      width: "24px",
                      height: "24px",
                      strokeWidth: 1.5,
                    }}
                  />
                </IconButton>
              )}
            </CustomDrawer>

            {accountInformation?.authenticate
              ? !isMobile && (
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                    onClick={handleClickUMenu}
                    sx={{ cursor: "pointer" }}
                  >
                    <Avatar
                      src={accountInformation?.data?.profilePic || defaultUser}
                      alt="Ankita Shah"
                      sx={{
                        width: "56px",
                        height: "56px",
                      }}
                    />

                    <Box>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        fontFamily={"Inter"}
                        fontWeight={500}
                      >
                        {accountInformation?.data?.firstName +
                          " " +
                          accountInformation?.data?.lastName}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="#666666"
                        fontFamily={"Inter"}
                        fontWeight={400}
                      >
                        {accountInformation?.data?.userName
                          ? `@${accountInformation?.data?.userName}`
                          : ""}
                      </Typography>
                    </Box>
                    {!isMobile && (
                      <img
                        src={darkMode ? whiteIcon : profile_drop_icon}
                        alt=""
                      />
                    )}
                  </Box>
                )
              : !isMobile && (
                  <Button
                    sx={{
                      //   p: "13px",
                      border: (theme) =>
                        `1px solid ${theme.palette.primary.main}`,
                      borderRadius: "6px",
                      fontSize: "18px",
                      fontWeight: 600,
                      textTransform: "capitalize",
                      fontFamily: "Inter",
                    }}
                    onClick={() => {
                      dispatch(setOpenDialog(true));
                    }}
                    variant="outlined"
                  >
                    Login Now
                  </Button>
                )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Permanent Sidebar for Desktop */}
      {!isMobile ? (
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              borderRight: "1px solid #ddd",
              p: "25px 20px 15px 20px",
              zIndex: 101,
              boxShadow: "none",
              position: "fixed",
              top: `${
                appBarRef?.offsetHeight
                  ? appBarRef?.offsetHeight + "px"
                  : "85px"
              }`,
              height: `calc(100% - ${
                appBarRef?.offsetHeight
                  ? appBarRef?.offsetHeight + "px"
                  : "85px"
              })`,
            },
          }}
          open
        >
          {drawerContent}
        </Drawer>
      ) : (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Improves performance on mobile
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxShadow: "none",
              p: "25px 20px 15px 20px",
              zIndex: 101,
            },
          }}
        >
          {drawerContent}
        </Drawer>
      )}

      {/* Temporary Sidebar for Mobile */}

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 3,
          width: `calc(100% - ${isMobile ? 0 : drawerWidth}px)`,
          mt: 8,
        }}
      >
        <Typography paragraph>
          {" "}
          <Outlet />
        </Typography>
      </Box>
      <Menu
        // className="notification_list"
        // id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl2}
        open={openUMenu}
        onClose={handleCloseUMenu}
      >
        <Box
          sx={{
            backgroundColor: darkMode ? "black" : "rgb(225, 225, 225)",
            width: "375px",
          }}
        >
          <Box
            style={{ color: darkMode ? "#FFFFFF" : "#040406" }}
            sx={{
              color: "#040406",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "700",
              letterSpacing: "-.3376471102px",
              lineHeight: "16.94px",
              padding: "15px",
              textAlign: "left",
            }}
          >
            {t("MyAccount")}
          </Box>
          {false && (
            <Box
              style={{
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              sx={{
                alignItems: "center",
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "row",
                paddingRight: "10px",
              }}
            >
              <Box
                style={{
                  color: darkMode ? "white" : "#040406",
                  backgroundColor: darkMode ? "#0E181E" : "white",
                }}
                sx={{
                  backgroundColor: "#fff",
                  color: "#040406",
                  cursor: "pointer",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "400",
                  height: "49px",
                  letterSpacing: "-.3376471102px",
                  lineHeight: "16.94px",
                  padding: "15px",
                  width: "375px",
                }}
              >
                {t("DarkTheme")}
              </Box>
              <Switch checked={checked} onChange={onDarkModeChange} />
            </Box>
          )}

          <Box
            sx={{ backgroundColor: "#1e1b31", border: ".5px", height: "1px" }}
          />
          <Box
            style={{
              backgroundColor: darkMode ? "#0E181E" : "white",
            }}
            onClick={onProfileClick}
            sx={{
              alignItems: "center",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Box
              style={{
                color: darkMode ? "white" : "#040406",
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              sx={{
                backgroundColor: "#fff",
                color: "#040406",
                cursor: "pointer",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
                height: "49px",
                letterSpacing: "-.3376471102px",
                lineHeight: "16.94px",
                padding: "15px",
                width: "375px",
              }}
            >
              {t("MyProfile")}
            </Box>
            <img
              style={{
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              className="foeword-arrow-style"
              src={darkMode ? BlackArrowIcon : forwordArrow}
              alt="forword-arrow"
            />
          </Box>
          <Box
            sx={{ backgroundColor: "#1e1b31", border: ".5px", height: "1px" }}
          />
          <Box
            style={{
              backgroundColor: darkMode ? "#0E181E" : "white",
            }}
            onClick={onPasswordChange}
            sx={{
              alignItems: "center",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Box
              style={{
                color: darkMode ? "white" : "#040406",
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              sx={{
                backgroundColor: "#fff",
                color: "#040406",
                cursor: "pointer",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
                height: "49px",
                letterSpacing: "-.3376471102px",
                lineHeight: "16.94px",
                padding: "15px",
                width: "375px",
              }}
            >
              {t("ChangePassword")}
            </Box>
            <img
              style={{
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              className="foeword-arrow-style"
              src={darkMode ? BlackArrowIcon : forwordArrow}
              alt="forword-arrow"
            />
          </Box>
          <Box
            sx={{ backgroundColor: "#1e1b31", border: ".5px", height: "1px" }}
          />
          <Box
            style={{
              backgroundColor: darkMode ? "#0E181E" : "white",
            }}
            onClick={onSaveItem}
            sx={{
              alignItems: "center",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Box
              style={{
                color: darkMode ? "white" : "#040406",
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              sx={{
                backgroundColor: "#fff",
                color: "#040406",
                cursor: "pointer",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
                height: "49px",
                letterSpacing: "-.3376471102px",
                lineHeight: "16.94px",
                padding: "15px",
                width: "375px",
              }}
            >
              {t("SavedItems")}
            </Box>
            <img
              style={{
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              src={darkMode ? BlackArrowIcon : forwordArrow}
              alt="forword-arrow"
            />
          </Box>
          <Box
            sx={{ backgroundColor: "#1e1b31", border: ".5px", height: "1px" }}
          />
          {false && (
            <Box
              style={{
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              onClick={onAccountBlock}
              sx={{
                alignItems: "center",
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "row",
                paddingRight: "10px",
              }}
            >
              <Box
                sx={{
                  color: darkMode ? "white" : "#040406",
                  backgroundColor: darkMode ? "#0E181E" : "white",
                }}
              >
                {t("BlockedAccounts")}
              </Box>
              <img
                style={{
                  backgroundColor: darkMode ? "#0E181E" : "white",
                }}
                className="foeword-arrow-style"
                src={darkMode ? BlackArrowIcon : forwordArrow}
                alt="forword-arrow"
              />
            </Box>
          )}
          <Box
            style={{ color: darkMode ? "#FFFFFF" : "#040406" }}
            sx={{
              color: "#040406",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "700",
              letterSpacing: "-.3376471102px",
              lineHeight: "16.94px",
              padding: "15px",
              textAlign: "left",
            }}
          >
            {t("Help")}
          </Box>
          <Box
            style={{
              backgroundColor: darkMode ? "#0E181E" : "white",
            }}
            onClick={onTermasandCondition}
            sx={{
              alignItems: "center",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Box
              style={{
                color: darkMode ? "white" : "#040406",
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              sx={{
                backgroundColor: "#fff",
                color: "#040406",
                cursor: "pointer",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
                height: "49px",
                letterSpacing: "-.3376471102px",
                lineHeight: "16.94px",
                padding: "15px",
                width: "375px",
              }}
            >
              {t("TermsCondition")}
            </Box>
            <img
              style={{
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              className="foeword-arrow-style"
              src={darkMode ? BlackArrowIcon : forwordArrow}
              alt="forword-arrow"
            />
          </Box>
          <Box
            sx={{ backgroundColor: "#1e1b31", border: ".5px", height: "1px" }}
          />
          <Box
            style={{
              backgroundColor: darkMode ? "#0E181E" : "white",
            }}
            onClick={onPrivacyPolicy}
            sx={{
              alignItems: "center",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Box
              style={{
                color: darkMode ? "white" : "#040406",
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              sx={{
                backgroundColor: "#fff",
                color: "#040406",
                cursor: "pointer",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
                height: "49px",
                letterSpacing: "-.3376471102px",
                lineHeight: "16.94px",
                padding: "15px",
                width: "375px",
              }}
            >
              {t("Policy")}
            </Box>
            <img
              style={{
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              className="foeword-arrow-style"
              src={darkMode ? BlackArrowIcon : forwordArrow}
              alt="forword-arrow"
            />
          </Box>

          <Box
            sx={{ backgroundColor: "#1e1b31", border: ".5px", height: "1px" }}
          />

          {/* <Box
            style={{
              backgroundColor: darkMode ? "#0E181E" : "white",
            }}
            onClick={onContactUs}
            sx={{alignItems: 'center',
backgroundColor: '#fff',
display: 'flex',
flexDirection: 'row',
paddingRight: '10px'}}
          >
            <Box
              style={{
                color: darkMode ? "white" : "#040406",
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              sx={{backgroundColor: '#fff',
color: '#040406',
cursor: 'pointer',
fontFamily: 'Inter',
fontSize: '14px',
fontWeight: '400',
height: '49px',
letterSpacing: '-.3376471102px',
lineHeight: '16.94px',
padding: '15px',
width: '375px'}}
            >
              {t("Contact Us")}
            </Box>
            <img
              style={{
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              className="foeword-arrow-style"
              src={darkMode ? BlackArrowIcon : forwordArrow}
              alt="forword-arrow"
            />
          </Box> */}

          <Box
            sx={{ backgroundColor: "#1e1b31", border: ".5px", height: "1px" }}
          />

          <Box
            style={{
              backgroundColor: darkMode ? "#0E181E" : "white",
            }}
            onClick={onHelp}
            sx={{
              alignItems: "center",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Box
              style={{
                color: darkMode ? "white" : "#040406",
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              sx={{
                backgroundColor: "#fff",
                color: "#040406",
                cursor: "pointer",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
                height: "49px",
                letterSpacing: "-.3376471102px",
                lineHeight: "16.94px",
                padding: "15px",
                width: "375px",
              }}
            >
              {t("HelpCenter")}
            </Box>
            <img
              style={{
                backgroundColor: darkMode ? "#0E181E" : "white",
              }}
              className="foeword-arrow-style"
              src={darkMode ? BlackArrowIcon : forwordArrow}
              alt="forword-arrow"
            />
          </Box>
          <Box
            sx={{ backgroundColor: "#1e1b31", border: ".5px", height: "1px" }}
          />
          <Box
            style={{
              color: "#E84127",
              fontWeight: "bold",
              backgroundColor: darkMode ? "#0E181E" : "white",
            }}
            onClick={onDeleteAccount}
            sx={{
              backgroundColor: "#fff",
              color: "#040406",
              cursor: "pointer",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "400",
              height: "49px",
              letterSpacing: "-.3376471102px",
              lineHeight: "16.94px",
              padding: "15px",
              width: "375px",
            }}
          >
            {t("DeleteAccount")}
          </Box>
          <Box
            sx={{ backgroundColor: "#1e1b31", border: ".5px", height: "1px" }}
          />
          <Box
            style={{
              color: "#E84127",
              fontWeight: "bold",
              backgroundColor: darkMode ? "#0E181E" : "white",
            }}
            onClick={handleLogOut}
            sx={{
              backgroundColor: "#fff",
              color: "#040406",
              cursor: "pointer",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "400",
              height: "49px",
              letterSpacing: "-.3376471102px",
              lineHeight: "16.94px",
              padding: "15px",
              width: "375px",
            }}
          >
            {t("Logout")}
          </Box>
        </Box>
      </Menu>
      <Modal
        open={deleteAccountModal}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="toggle_img_Show"
      >
        <div
          className="deleteModalContainer"
          style={{ flexDirection: "column", height: "100%", width: "100%" }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => 9999 }}
            open={loader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div
            className="deleteModalView"
            style={{
              backgroundColor: "#fff",
              borderRadius: "20px",
              position: "relative",
              width: "40%",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleDeleteClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                bgcolor: "#fff",
              }}
            >
              <Close />
            </IconButton>
            <div className="delete-account-txt-msg">
              {t("EnterUserName")}
              <div style={{ color: "#E84127", marginLeft: "6px" }}>
                {t("ToDeleteAccount")}
              </div>
            </div>
            <div className="delete-account-username-msg">
              {t("Username")}:{" "}
              <p className="delete-username-txt">
                {accountInformation?.data?.userName}
              </p>
            </div>
            <div className="form-group margin-delete-account-view">
              <label>{t("Username")}</label>
              <input
                type="text"
                className="form-control"
                value={username}
                onChange={onUserNameChange}
                placeholder={t("EnterYourUsername")}
              />
              {usernameError && (
                <span role="alert" className="error_text">
                  {usernameError}
                </span>
              )}
            </div>
            <div
              className="theme_btn margin-delete-account-view p-3"
              style={{ cursor: "pointer" }}
              onClick={onDeleteAccountFinal}
            >
              {t("DeleteAccount")}
            </div>
          </div>
        </div>
      </Modal>
    </Box>
  );
};

export default App;
