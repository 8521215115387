import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import back_icon from "../../../assets/icons/back_arrow.svg";
import back_icon_small from "../../../assets/icons/Back.svg";
import logo from "../../../assets/media/mainLogo.svg";
import ForwordWhite from "../../../assets/icons/whiteIcon/white-forword-arrow.svg";
import "./index.scss";
import SEO from "../../Components/SEO";

const renderLoader = () => <p></p>;

function AboutUs() {
  const darkMode = useSelector((state) => state.headerMenu.darkMode);
  const { t } = useTranslation();
  let navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div
      className="container-fluid p-0"
      style={{
        backgroundColor: darkMode ? "#040405" : "white",
        height: "100vh",
      }}
    >
      <SEO
        title="About Us - Maindiish: Discover Our Passion for Culinary Excellence"
        description="Discover MainDish's mission to enhance your digital experience with innovative solutions and dedicated service."
        url="https://maindiish.com/about-us"
        image="https://maindiish.com/mainLogo.png"
        keywords="How To Cook Recipes, Easy-to-Make Recipes, Cooking recipes, recipes and cooking tips, dinner ideas, cooking guide, Healthy Cooking Tips, everyday cooking recipes, Dinner Recipes, food idea,maindiishfood articles, recipe articles, cooking articles, food blog posts, culinary stories, restaurant reviews, food writing, cooking guides, food journalism, food culture, dining experiences, food trends, cooking tips, food news, culinary articles, food education, recipe collections, food inspiration, cooking tutorials, food community stories"
      />
      <Backdrop
        open={false}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section
          style={{ backgroundColor: darkMode ? "#040405" : "white" }}
          className="header_for_about"
        >
          <div className="container-fluid p-0">
            <div className="about_title d-none d-xl-block d-md-block d-sm-block d-xs-none">
              <Link onClick={handleBack}>
                <img src={darkMode ? ForwordWhite : back_icon} alt="" />
              </Link>
              <span
                className="ms-4"
                style={{ color: darkMode ? "white" : "#040406" }}
              >
                {t("About Us")}
              </span>
            </div>
            <div className="logo minHeight d-block d-xl-none d-md-none d-sm-none d-xs-block">
              <img src={logo} alt="" />
            </div>
          </div>
        </section>
        <div
          style={{ color: darkMode ? "white" : "#040406" }}
          className="col-md-12"
        >
          <div className="main_div">
            <div className="about_title_inside d-none d-xl-none d-md-none d-sm-none d-xs-block">
              <Link onClick={handleBack}>
                <img src={back_icon_small} alt="" />
              </Link>
              <span className="text-black ms-4">{t("Policy")}</span>
            </div>
            {/* <h5 className='mb-4'>
              Your Privacy Matters
            </h5> */}
            {/* <p>{privacy?.privacyAndPloicy}</p> */}
            <div
              style={{
                fontFamily: "Arial, sans-serif",
                lineHeight: "1.6",
                padding: "20px",
                color: "#333",
              }}
            >
              <h1 style={{ textAlign: "center", color: "#5A5A5A" }}>
                Why Maindiish Exists
              </h1>
              <p style={{ marginBottom: "20px" }}>
                Maindiish exists to revolutionize the way people share and
                celebrate their passion for food. We believe that recipes are
                more than just instructions—they are stories, memories, and a
                way to connect with others.
              </p>
              <p style={{ marginBottom: "20px" }}>
                Many food enthusiasts struggle to find a platform where their
                creativity is appreciated, and their culinary efforts can be
                monetized. Maindiish bridges this gap by creating a
                community-driven space where food lovers can:
              </p>
              <ul style={{ marginLeft: "20px", listStyleType: "disc" }}>
                <li style={{ marginBottom: "10px" }}>
                  Showcase their creations: Share unique recipes and kitchen
                  innovations.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  Inspire others: Connect with like-minded individuals and
                  exchange ideas.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  Earn from their talent: Turn their passion into a source of
                  income through affiliate marketing and content ads.
                </li>
              </ul>
              <p style={{ marginBottom: "20px" }}>
                Our mission is to empower food creators to do what they love
                while earning recognition and rewards for their work. Maindiish
                is more than an app; it’s a movement to redefine food-sharing
                and storytelling.
              </p>

              <div style={{ textAlign: "center", marginTop: "30px" }}>
                <a
                  href="https://www.facebook.com/people/Maindiish/61561936194597/?mibextid=LQQJ4d"
                  style={{
                    margin: "0 10px",
                    textDecoration: "none",
                    color: "#4267B2",
                    fontSize: "24px",
                    transition: "color 0.3s ease, transform 0.3s ease",
                    display: "inline-block",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  onMouseOver={(e) => {
                    e.currentTarget.style.color = "#365899";
                    e.currentTarget.style.transform = "scale(1.2)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.color = "#4267B2";
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  <i className="fab fa-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com/maindiishapp"
                  style={{
                    margin: "0 10px",
                    textDecoration: "none",
                    color: "#E1306C",
                    fontSize: "24px",
                    transition: "color 0.3s ease, transform 0.3s ease",
                    display: "inline-block",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  onMouseOver={(e) => {
                    e.currentTarget.style.color = "#C13584";
                    e.currentTarget.style.transform = "scale(1.2)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.color = "#E1306C";
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/maindiish/"
                  style={{
                    margin: "0 10px",
                    textDecoration: "none",
                    color: "#0077B5",
                    fontSize: "24px",
                    transition: "color 0.3s ease, transform 0.3s ease",
                    display: "inline-block",
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.color = "#005582";
                    e.currentTarget.style.transform = "scale(1.2)";
                  }}
                  target="_blank"
                  rel="noreferrer"
                  onMouseOut={(e) => {
                    e.currentTarget.style.color = "#0077B5";
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  <i className="fab fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </div>
  );
}

export default AboutUs;
