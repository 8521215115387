import moment from 'moment';

export function getTimeAgoShort(createdTime) {
    const now = moment();
    const diffInMinutes = now.diff(moment(createdTime), 'minutes');
    const diffInHours = now.diff(moment(createdTime), 'hours');
    const diffInDays = now.diff(moment(createdTime), 'days');
    const diffInWeeks = now.diff(moment(createdTime), 'weeks');
    const diffInMonths = now.diff(moment(createdTime), 'months');
    const diffInYears = now.diff(moment(createdTime), 'years');

    if (diffInMinutes < 60) {
        return `${diffInMinutes}m`;
    } else if (diffInHours < 24) {
        return `${diffInHours}h`;
    } else if (diffInDays < 7) {
        return `${diffInDays}d`;
    } else if (diffInWeeks < 4) {
        return `${diffInWeeks}w`;
    } else if (diffInMonths < 12) {
        return `${diffInMonths}mo`;
    } else {
        return `${diffInYears}y`;
    }
}

