import { forwardRef } from "react";
import { Icon } from "@iconify/react";

import Box from "@mui/material/Box";
import NoSsr from "@mui/material/NoSsr";

// ----------------------------------------------------------------------

export const Iconify = forwardRef(
  ({ className, width = 20, sx, ...other }, ref) => {
    const baseStyles = {
      width,
      height: width,
      flexShrink: 0,
      display: "inline-flex",
    };

    const renderFallback = (
      <Box component="span" sx={{ ...baseStyles, ...sx }} />
    );

    return (
      <NoSsr fallback={renderFallback}>
        <Box
          ref={ref}
          component={Icon}
          sx={{ ...baseStyles, ...sx }}
          {...other}
        />
      </NoSsr>
    );
  }
);
