import React, { lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import PublicRoute from "./PublicRoute";
import AuthHoc from "../Modules/Components/AuthHoc";
import RouteGuard from "../Modules/Components/RouteGuard";
import AboutUs from "../Modules/Pages/AboutUs";
import ContactUs from "../Modules/Pages/ContactUs";
import Sidebar from "../Shared/SideBar";
// import Login from "../pages/Login";
const ForegetPassword = lazy(() => import("../pages/ForegetPassword"));
const Congratulation = lazy(() => import("../pages/Congratulation"));
const NewLoginPassword = lazy(() => import("../pages/NewLoginPassword"));
const TypeCode = lazy(() => import("../pages/TypeCode"));
const TermsCondition = lazy(() => import("../Modules/Pages/Terms&Condition"));
// const PrivateRoute = lazy(() => import("./PrivateRoute"));
const Dashboard = lazy(() => import("../Modules/Pages/Dashboard"));
const ResponsiveDrawer = lazy(() => import("../Modules/Pages/SearchBar"));
const Blogs = lazy(() => import("../Modules/Pages/Blogs"));
const Brief = lazy(() => import("../Modules/Pages/Brief"));
const Studio = lazy(() => import("../Modules/Pages/Studio"));
const Settings = lazy(() => import("../Modules/Pages/Settings"));
const Trending = lazy(() => import("../Modules/Pages/Trending"));
const InBox = lazy(() => import("../Modules/Pages/InBox"));
const Profile = lazy(() => import("../Modules/Pages/Profile"));
const ChangePassword = lazy(() => import("../Modules/Pages/ChangePassword"));
const SaveItem = lazy(() => import("../Modules/Pages/SaveItem"));
const BlockedAccounts = lazy(() => import("../Modules/Pages/BlockedAccounts"));
const HelpCenter = lazy(() => import("../Modules/Pages/HelpCenter"));
const Register = lazy(() => import("../pages/Register"));
const PrivacyPolicy = lazy(() => import("../Modules/Pages/PrivacyPolicy"));
const Chats = lazy(() => import("../Modules/Pages/Chats"));
const Article = lazy(() => import("../Modules/Pages/Article"));
const ArticleDetail = lazy(() =>
  import("../Modules/Pages/Article/ArticleDetail")
);

const authRoute = [
  { path: "/", element: Dashboard },
  { path: "/article", element: Article },
  { path: "/search", element: ResponsiveDrawer },
  { path: "/posts", element: Blogs },
  { path: "/article/:id", element: ArticleDetail },
  { path: "/brief", element: Brief },
  { path: "/chats", element: Chats },
  { path: "/studio", element: Studio },
  // { path: "/settings", element: Settings },
  { path: "/trending", element: Trending },
  { path: "/inbox", element: InBox },
  { path: "/profile", element: Profile },
  { path: "/changePassword", element: ChangePassword },
  { path: "/saveItem", element: SaveItem },
  { path: "/blockedaccounts", element: BlockedAccounts },
  { path: "/helpcenter", element: HelpCenter },
];

export const [homeRoute, articleRoute, ...authRoutesList] = authRoute;

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Outlet />}>
      <Route path={"/"} element={<RouteGuard />}>
        <Route path={"/"} element={<Sidebar />}>
          {authRoute.map((item, index) => {
            const Component = item.element;
            const AuthComp = AuthHoc(Component);
            return <Route key={index} {...item} element={<AuthComp />} />;
          })}
        </Route>
      </Route>
      <Route path={"/"} element={<PublicRoute />}>
        {/* <Route path={"/"} element={<Login />} /> */}
        <Route path={"/register"} element={<Register />} />
        <Route path={"/forgot-passowrd"} element={<ForegetPassword />} />
        <Route path={"/type-code"} element={<TypeCode />} />
        <Route path={"/new-login-pasword"} element={<NewLoginPassword />} />
        <Route path={"/congratulation"} element={<Congratulation />} />
        <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
        <Route path={"/terms-condition"} element={<TermsCondition />} />
        <Route path={"/about-us"} element={<AboutUs />} />
        <Route path={"/contact-us"} element={<ContactUs />} />
      </Route>
    </Route>
  )
);

const AppRoute = () => {
  return <RouterProvider router={router} />;
};

export default AppRoute;
