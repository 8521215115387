import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { Container, Button, Typography, Grid } from "@mui/material";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import back_icon from "../../../assets/icons/back_arrow.svg";
import back_icon_small from "../../../assets/icons/Back.svg";
import logo from "../../../assets/media/mainLogo.svg";
import ForwordWhite from "../../../assets/icons/whiteIcon/white-forword-arrow.svg";
import "./index.scss";
import SEO from "../../Components/SEO";

const renderLoader = () => <p></p>;

function ContactUs() {
  const darkMode = useSelector((state) => state.headerMenu.darkMode);
  const { t } = useTranslation();
  let navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <SEO
        title="maindiish: Get in Touch for Support and Inquiries"
        description="Get in touch with maindiish for any inquiries or support. We're here to help!"
        url="https://maindiish.com/contact-us"
        image="https://maindiish.com/mainLogo.png"
        keywords="How To Cook Recipes, Easy-to-Make Recipes, Cooking recipes, recipes and cooking tips, dinner ideas, cooking guide, Healthy Cooking Tips, everyday cooking recipes, Dinner Recipes, food idea,maindiishfood articles, recipe articles, cooking articles, food blog posts, culinary stories, restaurant reviews, food writing, cooking guides, food journalism, food culture, dining experiences, food trends, cooking tips, food news, culinary articles, food education, recipe collections, food inspiration, cooking tutorials, food community stories"
      />
      <div
        className="container-fluid p-0"
        style={{
          backgroundColor: darkMode ? "#040405" : "white",
          height: "100vh",
        }}
      >
        <Backdrop
          open={false}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Suspense fallback={renderLoader()}>
          <section
            style={{ backgroundColor: darkMode ? "#040405" : "white" }}
            className="header_for_contact_us"
          >
            <div className="container-fluid p-0">
              <div className="contact_us_title d-none d-xl-block d-md-block d-sm-block d-xs-none">
                <Link onClick={handleBack}>
                  <img src={darkMode ? ForwordWhite : back_icon} alt="" />
                </Link>
                <span
                  className="ms-4"
                  style={{ color: darkMode ? "white" : "#040406" }}
                >
                  {t("Contact Us")}
                </span>
              </div>
              <div className="logo minHeight d-block d-xl-none d-md-none d-sm-none d-xs-block">
                <img src={logo} alt="" />
              </div>
            </div>
          </section>
          <div
            style={{ color: darkMode ? "white" : "#040406" }}
            className="col-md-12"
          >
            <div className="main_div">
              <div className="contact_us_title_inside d-none d-xl-none d-md-none d-sm-none d-xs-block">
                <Link onClick={handleBack}>
                  <img src={back_icon_small} alt="" />
                </Link>
                <span className="text-black ms-4">{t("Policy")}</span>
              </div>
              {/* -----------  Start Body ----------------- */}
              <Container
                maxWidth="sm"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "20px",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "8px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  marginTop: "50px",
                }}
              >
                <Typography
                  variant="h4"
                  align="center"
                  gutterBottom
                  style={{ color: "#333", marginBottom: "20px" }}
                >
                  Contact Us
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  style={{ color: "#555", marginBottom: "30px" }}
                >
                  Let’s get this conversation started. Tell us a bit about
                  yourself, and we’ll get in touch as soon as we can.
                </Typography>
                <Grid container spacing={2} style={{ width: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="input_container form-group"
                  >
                    <input
                      type="text"
                      className="form-control input"
                      placeholder="First Name"
                      style={{
                        borderRadius: "10px",
                        // color: darkMode ? "#e0e0e0" : "#212529",
                      }}
                      label="First Name"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="input_container form-group"
                  >
                    <input
                      type="text"
                      className="form-control input"
                      placeholder="Last Name"
                      style={{ borderRadius: "10px" }}
                      label="First Name"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} className="input_container form-group">
                    <input
                      type="EmailAddress"
                      className="form-control input"
                      placeholder="Enter your email"
                      style={{ borderRadius: "10px" }}
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} className="input_container form-group">
                    <textarea
                      className=" form-control input"
                      placeholder="Message"
                      style={{
                        height: "200px",
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginTop: "20px",
                    padding: "10px 20px",
                    backgroundColor: "#E84127",
                    borderRadius: "10px",
                  }}
                >
                  Submit
                </Button>
              </Container>
            </div>
          </div>
        </Suspense>
      </div>
    </>
  );
}

export default ContactUs;
